<script>
// import { getEarnings } from "@/services/earnings.service";
export default {
  props: {
    purchaseEarnings: {
      type: Array,
      default: () => {
        return []
      },
    },
    fetchingEarnings: {
      type: Boolean,
      default: () => {
        return false
      }
    },
  },
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      // isBusy: false,
      filterOn: [],
      sortBy: "code",
      sortDesc: true,
      fields: [
        '#',
        'id',
        {
          key: "created_at",
          formatter: (value) => {
            return this.$options.filters.dateFormat(value);
          },
        },
        'estate',
        'customer',
        'referred_by',
        {
          key: "purchase",
          label: "Amount Paid",
          sortable: true,
          formatter: (value) => {
            return this.$options.filters.currencyFormat(value.amount_paid);
          },
        },
        {
          key: "rate",
          label: "Commission %",
        },
        {
          key: "amount",
          label: "Commission Amount",
          formatter: (value) => {
            return this.$options.filters.currencyFormat(value);
          },
        },
        'payment_status'
      ],
    };
  },
  computed: {
    rows() {
      return this.purchaseEarnings.length;
    },
    isBusy() {
      return this.fetchingEarnings
    },
    username() {
      return this.$store.state.auth.user
        ? this.$store.state.auth.user.username
        : null;
    },
  },
  mounted: async function () {
    // this.isBusy = this.fetchingEarnings
    // Set the initial number of items
    this.totalRows = this.purchaseEarnings.length;
    // this.isBusy = false
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <b-dropdown
        right
        toggle-class="arrow-none card-drop"
        class="float-right"
        variant="white"
      >
        <template v-slot:button-content>
          <i class="mdi mdi-dots-vertical"></i>
        </template>
        <!-- item-->
        <b-dropdown-item>Sales Report</b-dropdown-item>
        <!-- item-->
        <b-dropdown-item>Export Report</b-dropdown-item>
        <!-- item-->
        <b-dropdown-item>Profit</b-dropdown-item>
        <!-- item-->
        <b-dropdown-item>Action</b-dropdown-item>
      </b-dropdown>

      <h4 class="card-title mb-4">All Land Sales Earnings</h4>
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show &nbsp;
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select
              >&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <div class="table-responsive">
        <b-table
          :items="purchaseEarnings"
          :fields="fields"
          responsive="sm"
          :busy="isBusy"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          show-empty
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> &nbsp; Loading...</strong>
            </div>
          </template>
          <template v-slot:cell(payment_status)="data">
            <div
              class="badge font-size-12"
              :class="{
                'badge-soft-danger': `${data.item.purchase.payment_status}` === 'installment',
                'badge-soft-success': `${data.item.purchase.payment_status}` === 'completed',
                'badge-soft-warning': `${data.item.purchase.payment_status}` === 'pending',
              }"
            >
              {{ data.item.purchase.payment_status }}
            </div>
          </template>
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(id)="data">
            {{ data.item.purchase.code }}
          </template>
          <template #cell(estate)="data">
            {{ data.item.purchase.estate.name }}
          </template>
          <template #cell(customer)="data">
            {{ data.item.purchase.customer.firstname }} {{ data.item.purchase.customer.lastname }}
          </template>
          <template #cell(referred_by)="data">
            {{ data.item.purchase.agent.username == username ? '@Me' : data.item.purchase.agent.username }}
          </template>
          <template v-slot:cell(action)>
            <a
              href="javascript:void(0);"
              class="mr-3 text-primary"
              v-b-tooltip.hover
              data-toggle="tooltip"
              title="Edit"
            >
              <i class="mdi mdi-pencil font-size-18"></i>
            </a>
            <a
              href="javascript:void(0);"
              class="text-danger"
              v-b-tooltip.hover
              title="Delete"
            >
              <i class="mdi mdi-trash-can font-size-18"></i>
            </a>
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>