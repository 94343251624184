<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import Stat from "./statistics";
import AdminStat from "./admin-statistics";
import Earnings from "./earnings";
import Landbuyers from '@/views/pages/lands/landBuyers';
import { getEarnings } from "@/services/earnings.service";
import roleAccessControl from '../../role-access-control.vue';

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Stat,
    Earnings,
    roleAccessControl,
    AdminStat,
    Landbuyers
  },
  data() {
    return {
      title: "Dashboard",
      fetchingEarnings: false,
      purchaseEarnings: [],
      signupEarnings: [],

      // for admin
      purchases: [],
      signupPayments: []
    };
  },
  computed: {
    authUser() {
      return this.$store.state.auth.user;
    },
    affiliateSignupEarnings() {
      console.log("signupEarning: " + this.signupEarnings);
      var cummulative = this.signupEarnings.reduce(
        (sum, earning) => sum + parseInt(earning.amount),
        0
      );
      const count = this.signupEarnings.length;
      return {
        cummulative: cummulative,
        count,
      };
    },
    affiliatePurchaseEarnings() {
      var result = [];
      this.purchaseEarnings.reduce(function (res, value) {
        if (!res[value.type]) {
          res[value.type] = { type: value.type, cummulative: 0, count: 0 };
          result.push(res[value.type]);
        }
        res[value.type].cummulative += parseFloat(value.amount);
        res[value.type].count++;
        return res;
      }, {});
      console.log(result);
      var commission = result.filter((earning) => earning.type == "commission")[0]
      var direct_referrer = result.filter((earning) => earning.type == "direct referrer")[0]
      var indirect_referrer = result.filter((earning) => earning.type == "indirect referrer")[0]
      return {
        commission,
        direct_referrer,
        indirect_referrer
      };
    },
    computedPurchases() {
      console.log('purchases')
      console.log(this.purchases)
      var totalPurchase  = this.purchases.reduce((sum, purchase) => {
        return sum + parseFloat(purchase.amount_paid)
      }, 0)

      return {
        cummulative: totalPurchase,
        count: this.purchases.filter((purchase) => purchase.amount_paid > 0).length
      }
    },
    computedSignupPayments() {
      console.log('purchases')
      console.log(this.signupPayments)
      var totalSignups  = this.signupPayments.reduce((sum, payment) => {
        return parseFloat(sum) + parseFloat(payment.amount)
      }, 0)

      return {
        cummulative: totalSignups,
        count: this.signupPayments.length
      }
    }
  },
  mounted() {
    this.fetchEarnings();
  },
  methods: {
    async fetchEarnings() {
      try {
        this.fetchingEarnings = true;
        let response = await getEarnings();

        if (['super-admin', 'admin'].includes(this.authUser.iam)) {
          // admin dashboard
          this.purchases = response.data.data.purchases;
          this.signupPayments = response.data.data.signupPayments;
        } else {
          this.purchaseEarnings = response.data.data.purchaseEarnings;
          this.signupEarnings = response.data.data.signupEarnings;
        }

        this.fetchingEarnings = false;
        console.log(response);
      } catch (error) {
        this.fetchingEarnings = false;
        if (error.response) {
          switch (error.response.status) {
              case 422:
                console.log(error.response.data.errors);
                this.errors = error.response.data.errors;
                break;
              case 403 || 401:
                this.$store.dispatch(
                  "notification/error",
                  'Unauthorized'
                );
                this.$router.push('/logout');
                break;
              case 500:
                this.$store.dispatch(
                  "notification/error",
                  error.response.data.message
                );
                break;
              default:
                this.$store.dispatch(
                  "notification/error",
                  "Something went wrong. Please try again!"
                );
                break;
            }
        }
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <b-overlay :show="fetchingEarnings" rounded="md" opacity="0.8">
      <div class="row">
        <div class="col-xl-12">
          <roleAccessControl role='agent'>
            <Stat
              :affiliateSignupEarnings="affiliateSignupEarnings"
              :affiliatePurchaseEarnings="affiliatePurchaseEarnings"
            />
          </roleAccessControl>
          <roleAccessControl role='admin,super-admin'>
            <AdminStat
              :purchases="computedPurchases"
              :signupPayments="computedSignupPayments"
            />
          </roleAccessControl>
        </div>
      </div>
    </b-overlay>
      <div class="row">
        <div class="col-lg-12">
          <roleAccessControl role='agent'>
            <Earnings :purchaseEarnings="purchaseEarnings" :fetchingEarnings="fetchingEarnings"/>
          </roleAccessControl>
          <roleAccessControl role='admin,super-admin'>
            <Landbuyers />
          </roleAccessControl>
        </div>
      </div>
  </Layout>
</template>