

<script>
import appConfig from "@/app.config";
/**
 * Widget Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  props: {
    affiliateSignupEarnings: {
      type: Object,
      default: () => {
        return {
          cummulative: 0,
          count: 0,
        };
      },
    },
    affiliatePurchaseEarnings: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    commissionEarnings () {
      return this.affiliatePurchaseEarnings.commission ? this.affiliatePurchaseEarnings.commission : {
        cummulative: 0,
        count: 0,
      };
    },
    directReferrerEarnings () {
      return this.affiliatePurchaseEarnings.direct_referrer ? this.affiliatePurchaseEarnings.direct_referrer : {
        cummulative: 0,
        count: 0,
      }
    },
    indirectReferrerEarnings () {
      return this.affiliatePurchaseEarnings.indirect_referrer ? this.affiliatePurchaseEarnings.indirect_referrer : {
        cummulative: 0,
        count: 0,
      }
    },
    totalEarnings () {
      return this.commissionEarnings.cummulative + this.directReferrerEarnings.cummulative + this.indirectReferrerEarnings.cummulative + this.affiliateSignupEarnings.cummulative
    }
  }
};
</script>

<template>
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <b-card style="background: #4c0410" class="text-white-50">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2 text-white">
                Affiliate Signup Commision
              </p>

              <h2 class="text-white font-size-30">
                {{ affiliateSignupEarnings.cummulative | currencyFormat }}
                <!-- ₦155,000 -->
              </h2>
              <h5
                class="text-white font-size-30"
                style="
                  border-top: 1px dashed white;
                  padding-top: 10px;
                  width: 200px;
                "
              >
                Affiliates Referred: {{ affiliateSignupEarnings.count }}
              </h5>
            </div>
            <div class="text-primary">
              <i
                class="ri-user-received-2-line"
                style="font-size: 40px; color: white"
              ></i>
            </div>
          </div>
        </div>
      </b-card>
    </div>
    <div class="col-sm-12 col-md-6">
      <b-card style="background: #444444" class="text-white-50">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2 text-white">
                My Land Sales Commision
              </p>
              <h2 class="text-white font-size-30">{{ commissionEarnings.cummulative | currencyFormat }}</h2>
              <h5
                class="text-white font-size-30"
                style="
                  border-top: 1px dashed white;
                  padding-top: 10px;
                  width: 200px;
                "
              >
                Lands Sold: {{ commissionEarnings.count }}
              </h5>
            </div>
            <div class="text-primary">
              <i
                class="ri-building-line"
                style="font-size: 40px; color: white"
              ></i>
            </div>
          </div>
        </div>
      </b-card>
    </div>
    <div class="col-sm-12 col-md-6">
      <b-card class="text-white-50" style="background: #990000">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-16 mb-2 text-white">
                Direct-Affiliate Land Sales
              </p>
              <h2 class="font-size-30 text-white">{{ directReferrerEarnings.cummulative | currencyFormat }}</h2>
              <h5
                class="text-white font-size-30"
                style="
                  border-top: 1px dashed white;
                  padding-top: 10px;
                  width: 200px;
                "
              >
                Lands Sold: {{ directReferrerEarnings.count }}
              </h5>
            </div>
            <div class="text-primary">
              <i
                class="ri-briefcase-4-line"
                style="font-size: 40px; color: white"
              ></i>
            </div>
          </div>
        </div>
      </b-card>
    </div>
    <div class="col-sm-12 col-md-6">
      <b-card class="text-white-50" style="background: #808080">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-16 mb-2 text-white">
                Indirect-Affiliate Land Sales
              </p>
              <h2 class="font-size-30 text-white">{{ indirectReferrerEarnings.cummulative | currencyFormat }}</h2>
              <h5
                class="text-white font-size-30"
                style="
                  border-top: 1px dashed white;
                  padding-top: 10px;
                  width: 200px;
                "
              >
                Lands Sold: {{ indirectReferrerEarnings.count }}
              </h5>
            </div>
            <div class="text-primary">
              <i
                class="ri-briefcase-4-line"
                style="font-size: 40px; color: white"
              ></i>
            </div>
          </div>
        </div>
      </b-card>
    </div>
    <div class="col-sm-12 col-md-12">
      <b-card style="background: #ffffff" class="text-black-50">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-16 mb-2 text-black">
                Total Earning
              </p>
              <h2 class="mb-0 text-black font-size-30">{{ totalEarnings | currencyFormat }}</h2>
            </div>
            <div class="text-primary">
              <i class="ri-coin-line" style="font-size: 40px; color: black"></i>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
  <!-- end row -->
</template>